@import "./base.scss";

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-bold;
}
a {
  color: $txt-color;
  @include transition($animation);
}
/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/
p {
  line-height: 1.5em;
}
p:last-child {
  padding-bottom: 0;
}

ul li {
  list-style: none;
}
ol {
  list-style-position: inside;
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}
.font-semiBold {
  font-family: $font-family-semiBold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.main-container {
  padding: 1vw;
  width: 100%;
  height: 95%;
  .inner-header-container {
    width: 100%;
    height: 6%;
    border-radius: 7px;
    padding-left: 1%;
    display: flex;
    align-items: center;
    @include shadow-large;
    .left-part {
      display: flex;
      height: 100%;
      align-items: center;
      width: 90%;
      .close-icon {
        background-image: url("assets/icons/close.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 16px;
        cursor: pointer;
      }
      .inner-title {
        font-size: 1.35rem;
        margin-left: 1%;
        text-transform: uppercase;
      }
    }
  }
  .body-container {
    height: 91%;
    margin-top:1%;
    border-radius: 7px;
    padding:1vw;
    @include shadow-large;
    &.body-map{
      padding:0px;
      box-shadow: none;
      margin-top:0%;
    }
    &.row{
      display: flex;
    }
  }
}
/*end BASE*/
