@import "../../base.scss";

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dropdown {
  margin: 0 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 25%;
  height: 50px;
  width: 70px;
  background-image: url("../../assets/icons/menu.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 2%;
}

.dropdown .dropbtn {
  font-size: 15px;
  font-family: $font-family-regular;
  border: none;
  outline: none;
  background-color: inherit;
  margin: 0;

  &.selected {
    font-family: $font-family-bold;
    white-space: nowrap;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 17;
  top: 6%;
  left: 79%;
  background-color: white;
  min-width: 350px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content .buttonche {
  font-size: 16px;
  width: 100%;
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  @include shadow-small;
}

.dropdown-content,
.dropdown-content:hover,
.show {
  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
  display: block;
}

.profile {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  .text {
    width: 85%;
    height: 45px;
    font-size: 18px;
    font-family: $font-family-bold;
    display: flex;
    align-items: center;
    color: #0e1833;
    margin-left: 3%;
  }
  .exit {
    background-image: url("../../assets/icons/close.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 25px;
    width: 17px;
  }
}
.info {
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
  .avatar {
    background-image: url("../../assets/images/default-profile-picture.png");
    background-size: contain;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 2%;
  }
  .name {
    font-size: 15px;
    font-family: $font-family-bold;
    white-space: nowrap;
  }
  .phone-number {
    font-size: 14px;
    font-family: $font-family-regular;
    letter-spacing: 0.5px;
    white-space: nowrap;
    margin: 5px 0px;
  }
}

.user-management {
  width: 95%;
  border: 1px solid #c5d7f2;
  display: flex;
  height: 40px;
  margin-left: 2.5%;
  align-items: center;
  border-radius: 7px;
  justify-content: space-between;
  cursor: pointer;
  .user-management-text {
    font-size: 15px;
    color: #0e1833;
    font-family: $font-family-bold;
    margin-left: 2%;
  }
  .user-management-icon {
    background-image: url("../../assets/icons/users.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 20px;
    margin-right: 2%;
  }
  .merchants-icon{
    background-image: url("../../assets/icons/merchants-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 22px;
  }
}

.log-out {
  width: 95%;
  border: 1px solid #f5ccdb;
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 7px;
  justify-content: space-between;
  cursor: pointer;
  margin: 10px 8px;
  .log-out-text {
    font-size: 15px;
    color: #0e1833;
    font-family: $font-family-bold;
    margin-left: 2%;
  }
  .log-out-icon {
    background-image: url("../../assets/icons/logout.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 25px;
    margin-right: 1%;
  }
}

.header-inner-element {
  font-size: 16px;
  font-family: $font-family-semiBold;
}
.header-container {
  display: flex;
  flex-flow: row;
  height: 6%;
  justify-content: space-between;
  border-radius: 10px;
  @include shadow-large;
  width: 100%;
  .left-header-container {
    align-items: center;
    display: flex;
    width: 25%;
    .logo {
      background-image: url("../../assets/icons/mini-logo.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      height: 37px;
      width: 45%;
      cursor: pointer;
    }
  }

  .middle-header-container {
    /*
    -> When we show Finances and Website tabs
    width: 50%;
    */
    width: 40%;
    justify-content: center;
    display: flex;
    .header-element {
      display: flex;
      font-family: $font-family-semiBold;
      justify-content: center;
      align-items: center;
      width: 18%;
      height: 100%;
      color: black;
      cursor: pointer;
      &.selected {
        font-family: $font-family-bold;
        color: $main-color;
        border-bottom: 3px solid $main-color;
      }
      .header-inner-element {
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: nowrap;
        &.selected {
          font-family: $font-family-bold;
        }
      }
    }
  }
}
.right-container {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .right-header-container {
    .dropdown .dropbtn {
      font-size: 17px;
    }
  }

  .header-inner-element {
    font-size: 15px;
  }
  .header-element {
    &.button {
      font-size: 13px;
      padding: 0 26px;
      height: 40%;
    }
  }
  .dropdown-content {
    left: 75.5%;
  }
}

@media screen and (max-width: 1300px) {
  .right-header-container {
    .dropdown .dropbtn {
      font-size: 14px;
    }
  }

  .header-inner-element {
    font-size: 14px;
  }
  .right-header-container {
    .header-element {
      &.button {
        font-size: 10px;
        padding: 0 20px;
        height: 30%;
        margin-top: 5%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .right-header-container {
    .header-element {
      &.button {
        font-size: 10px;
        padding: 0 20px;
        height: 30%;
        margin-top: 6%;
      }
    }
  }
}
